import { Button } from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'

const defaultUrl = 'https://altagenda.crossuite.com/MoeFit/'

export function MakeAppointment({ size, url }) {
  return (
    <Button
      color={'secondary'}
      variant="contained"
      size={size}
      href={url ?? defaultUrl}
      target="_blank"
      rel="noopener nofollow"
      endIcon={<AddIcon />}
    >
      Afspraak maken
    </Button>
  )
}
