import React from 'react'
import {
  makeStyles,
  TextField,
  FormControl,
  Button,
  Snackbar,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  contactForm: {
    width: '100%',
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}))
const schema = yup.object().shape({
  fullName: yup.string().required(),
  email: yup.string().required().email(),
  message: yup.string().required(),
})

const Alert: React.FC<AlertProps> = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const Contact: React.FC = () => {
  const classes = useStyles(undefined)
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  })
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)

  const submitForm = async (data: {
    fullName: string
    email: string
    message?: string
    toc?: string
  }) => {
    if (data.toc) {
      return
    }
    setDisabled(true)
    try {
      const response = await fetch('/.netlify/functions/sendEmail', {
        method: 'POST',
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response
        setOpen(true)
        setError(true)
        return
      }
      setDisabled(false)
      setOpen(true)
    } catch (e) {
      setError(true)
      setDisabled(false)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            id="fullName"
            variant="outlined"
            label="Naam en voornaam *"
            name="fullName"
            color={'secondary'}
            inputRef={register}
            error={errors.fullName ? true : false}
            helperText={
              errors.fullName ? 'Naam en voornaam zijn verplicht' : undefined
            }
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            id="email"
            variant="outlined"
            label="E-mail"
            name="email"
            color={'secondary'}
            inputRef={register}
            error={errors.email ? true : false}
            helperText={
              errors.email ? 'Je e-mail adres is verplicht' : undefined
            }
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="message"
            variant="outlined"
            label="Bericht/vraag*"
            name="message"
            color={'secondary'}
            error={errors.message ? true : false}
            helperText={
              errors.message ? 'Laat een berichtje achter' : undefined
            }
            rows={9}
            multiline
            fullWidth
            inputRef={register}
          />
        </FormControl>
        <FormControl
          className={classes.formControl}
          style={{ position: 'absolute', left: '-10000px' }}
        >
          <TextField
            id="toc"
            variant="outlined"
            label="Ben je akkoord ?"
            name="toc"
            color={'secondary'}
            fullWidth
            hidden
            inputRef={register}
          />
        </FormControl>
        <Button
          variant="outlined"
          color="secondary"
          size={'large'}
          style={{ float: 'right' }}
          type="submit"
          disabled={disabled}
        >
          Verstuur bericht
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <>
          {!error && <Alert severity="success">Bericht goed ontvangen !</Alert>}
          {error && <Alert severity="error">Er is iets misgelopen. </Alert>}
        </>
      </Snackbar>
    </>
  )
}

export default Contact
