import React from 'react'
import Layout from '../components/layout'
import { Container, Grid, Typography, Theme, Paper } from '@material-ui/core'
import { graphql } from 'gatsby'
import { ContactPageQuery } from '../../@types/graphql-types'

import { HelmetProvider } from 'react-helmet-async'
import { makeStyles } from '@material-ui/styles'

import ContactForm from '../components/ContactForm'

import { MakeAppointment } from '../components/MakeAppointment'
import Footer from '../components/Footer'
import IconGrid from '../components/IconGrid'
import SEO from '../components/SEO'
interface ContactPageProps {
  data: ContactPageQuery
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(15, 0, 4, 0),
  },
  formRow: {
    marginTop: theme.spacing(4),
  },
}))

const ContactPage: React.FC<ContactPageProps> = (props) => {
  const classes = useStyles(props)

  return (
    <HelmetProvider>
      <Layout noHeader>
        <SEO title="Contact" />
        <Container maxWidth="lg">
          <Grid container className={classes.root}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant={'h3'} component={'h2'} gutterBottom>
                Contacteer ons
              </Typography>
              <Typography variant={'subtitle1'} gutterBottom>
                We komen zo snel mogelijk bij je terug.
              </Typography>
              <MakeAppointment size={'medium'} />
            </Grid>
            <Grid xs={12} sm={6} md={8} className={classes.formRow}>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
        <Paper>
          <Container maxWidth="lg">
            <IconGrid />
          </Container>
        </Paper>

        <Container maxWidth="lg">
          <Footer />
        </Container>
      </Layout>
    </HelmetProvider>
  )
}

export const pageQuery = graphql`
  query ContactPage {
    sanitySiteSettings {
      siteUrl
      title
      description
      logo {
        asset {
          fixed(width: 125) {
            src
          }
        }
      }
    }
  }
`
export default ContactPage
