import {
  Avatar,
  Box,
  Button,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
} from '@material-ui/core'
import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import RestoreIcon from '@material-ui/icons/Restore'
export const IconGrid = (props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    icon: {
      fontSize: '2rem',
      color: 'white',
    },
    iconGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: 'repeat(1, 1fr)',
      // gridAutoRows: '20px',
      gridTemplateAreas: `
      "ThingsToBringTitle ThingsToBringTitle ThingsToBringTitle ThingsToBringTitle"
      "ThingsToBring ThingsToBring ThingsToBring ThingsToBring"
      "Title Title Title Title"
      "GoodToKnow GoodToKnow GoodToKnow GoodToKnow"`,
      gap: `${theme.spacing(3)}px 0`,
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    avatar: {
      marginTop: theme.spacing(1.5),
      width: '5rem',
      height: '5rem',
      backgroundColor: theme.palette.secondary.main,
      margin: '0 auto',
    },
    thingDescription: {
      fontSize: '1rem',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      maxWidth: `160px`,
      margin: '0 auto',
      marginTop: theme.spacing(1.5),
    },

    ThingsToBring: {
      gridArea: 'ThingsToBring',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
    Title: {
      gridArea: 'Title',
    },
    ThingsToBringTitle: {
      gridArea: 'ThingsToBringTitle',
    },
    GoodToKnow: {
      gridArea: 'GoodToKnow',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
  }))
  const classes = useStyles(undefined)
  const ThingsToBring = [
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M16.431641 1.9824219L15.017578 3.3964844L17.109375 5.4882812L14.960938 7.6367188L11.955078 4.6328125L4.03125 12.544922L4.0019531 18.595703L1.9746094 20.623047L3.3886719 22.037109L5.4257812 20L11.414062 20L11.707031 19.707031L19.369141 12.046875L16.375 9.0527344L18.523438 6.9023438L20.603516 8.9824219L22.017578 7.5683594L16.431641 1.9824219 z M 11.953125 7.4589844L16.539062 12.046875L14.984375 13.601562L12.736328 11.351562L11.322266 12.765625L13.570312 15.015625L12 16.585938L9.7070312 14.292969L8.2929688 15.707031L10.583984 18L6.0039062 18L6.0273438 13.378906L11.953125 7.4589844 z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Doktersvoorschrift',
    },
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M16,13h-3v3h-2v-3 H8v-2h3V8h2v3h3V13z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Klevertje mutualiteit',
    },
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6c0-1.1-0.9-2-2-2H4C2.895,4,2,4.895,2,6z M9,8c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C7,8.895,7.895,8,9,8z M6,15.1c0-0.998,1.999-1.5,3-1.5s3,0.502,3,1.5V16H6V15.1z M18,11h-4V9h4 V11z M18,15h-4v-2h4V15z M20,18H4V6h16V18z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Identiteitskaart',
    },
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M5.84375 2C5.371094 2 5 2.449219 5 3C3.898438 3 3 3.898438 3 5L3 6L21 6L21 5C21 3.898438 20.101563 3 19 3C19 2.449219 18.628906 2 18.15625 2 Z M 4 7L4 20C4 21.105469 4.894531 22 6 22L18 22C19.105469 22 20 21.105469 20 20L20 7 Z M 6 9L18 9L18 19L6 19 Z M 9 10L9 15L10 15L10 13L10.375 13L12.53125 15.96875L11 18L12 18L13.03125 16.65625L14 18L15 18L13.53125 15.96875L15 14L14 14L13.03125 15.28125L11.34375 12.96875C12.011719 12.816406 12.5 12.214844 12.5 11.5C12.5 10.671875 11.828125 10 11 10 Z M 10 11L11 11C11.277344 11 11.5 11.222656 11.5 11.5C11.5 11.777344 11.277344 12 11 12L10 12Z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Eventuele verslagen/ beeldvorming ',
    },
  ]
  const goodToKnow = [
    {
      icon: (
        <RestoreIcon {...props} className={classes.icon} fontSize="large" />
      ),
      description: `Een behandeling duurt gemiddeld \n 30 min.`,
    },
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M12 2C8.9883724 2 6.3037203 3.3556682 4.4707031 5.4707031L2 3L2 9L8 9L5.8769531 6.8769531C7.3450482 5.1245479 9.5396099 4 12 4C16.411 4 20 7.589 20 12L22 12C22 6.486 17.514 2 12 2 z M 2 12C2 16.091 4.474 19.607297 8 21.154297L8 18.910156C5.613 17.526156 4 14.952 4 12L2 12 z M 12.5 14C11.130937 14 10 15.130937 10 16.5L10 17L12 17L12 16.5C12 16.213063 12.213063 16 12.5 16C12.786937 16 13 16.213063 13 16.5C13 16.757858 12.652593 17.557212 12.082031 18.298828C11.511469 19.040445 10.791372 19.762633 10.314453 20.210938L10 20.507812L10 22L15 22L15 20L13.222656 20C13.381275 19.814548 13.515452 19.715819 13.667969 19.517578C14.347407 18.634445 15 17.683142 15 16.5C15 15.130937 13.869063 14 12.5 14 z M 17 14L17 20L20 20L20 22L22 22L22 14L20 14L20 18L19 18L19 14L17 14 z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Annuleren: uiterlijk 24u op voorhand',
    },
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M5 3C3.9 3 3 3.9 3 5L3 19C3 20.1 3.9 21 5 21L19 21C20.1 21 21 20.1 21 19L21 5C21 3.9 20.1 3 19 3L5 3 z M 5 5L19 5L19 19L5 19L5 5 z M 11.980469 7C10.630469 7 9.68 7.0909375 9 7.2109375L9 17L11 17L11 13.410156C11.2 13.440156 11.649453 13.490234 11.939453 13.490234C13.239453 13.490234 14.349609 13.169219 15.099609 12.449219C15.679609 11.889219 16 11.059844 16 10.089844C16 9.1198437 15.580937 8.3007812 14.960938 7.8007812C14.310937 7.2707812 13.340469 7 11.980469 7 z M 12.089844 8.6894531C13.189844 8.6894531 13.820312 9.2403906 13.820312 10.150391C13.820312 11.170391 13.089922 11.769531 11.919922 11.769531C11.599922 11.769531 11.19 11.700391 11 11.650391L11 8.8105469C11.16 8.7705469 11.649844 8.6894531 12.089844 8.6894531 z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Parkeren met \nblauwe schijf',
    },
    {
      icon: (
        <SvgIcon {...props} className={classes.icon}>
          <path
            d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
            fill="#FFFFFF"
          />
        </SvgIcon>
      ),
      description: 'Afrekenen per sessie met Bancontact',
    },
  ]
  return (
    <Box className={classes.iconGrid}>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.ThingsToBringTitle}
      >
        Praktisch
        <Typography gutterBottom>
          Dit dien je mee te nemen bij je eerste afspraak
        </Typography>
      </Typography>
      <Box className={classes.ThingsToBring}>
        {ThingsToBring.map((thing) => {
          return (
            <Box key={thing.description}>
              <Avatar className={classes.avatar}>{thing.icon}</Avatar>
              <Typography className={classes.thingDescription} gutterBottom>
                {thing.description}
              </Typography>
            </Box>
          )
        })}
      </Box>
      <Typography variant="h4" className={classes.Title} gutterBottom>
        Handig om te weten
      </Typography>
      <Box className={classes.GoodToKnow}>
        {goodToKnow.map((thing) => {
          return (
            <Box key={thing.description}>
              <Avatar className={classes.avatar}>{thing.icon}</Avatar>
              <Typography className={classes.thingDescription} gutterBottom>
                {thing.description}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default IconGrid
